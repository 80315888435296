.signup-main {
    padding-top: 1rem;
    background-color: #FCFDFF;
}
.signup-main .signup-form .ant-form-item {
    width: 25vw;
}
.image-input-width {
    width: 25vw;
}
.signup-main .ant-space-compact {
    width: 25vw;
}
.signup-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;
}
.signup-main p {
    padding: 0;
}
.signup-main .forgot-pwd-btn {
    width: 30%;
}
.signup-main button {
    margin-top: 0.6rem;
}
.signup-main .create-account-btn {
    cursor: pointer;
    margin-left: 5px;
}
.signup-main .sign-in-btn {
    margin-bottom: 1rem;
}
@media screen and (max-width: 768px) {
    .signup-main {
        padding: 2rem 0;
    }
    .signup-main .signup-form .ant-form-item {
        width: 80vw;
    }
    .image-input-width {
        width: 80vw;
    }
    .signup-main .ant-space-compact {
        width: 80vw;
    }
}