.home-main {
    min-height: 100vh;
}
.header-section {
    background-color: #F5F6F9;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}
.categories-main {
    max-width: 100%;
    /* overflow: hidden; */
    overflow-x: auto;
    margin-top: 1rem;
}
.category-item {
    margin-right: 1rem;
    width: 8rem;
}
.category-item .category-card {
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.category-item .selected-category {
    border: 1px solid #C9CBD5
}
.category-card img {
    width: 4rem;
    /* aspect-ratio: 1; */
    object-fit: cover;
}
.body-section {
    background-color: #FCFDFF;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

@media screen and (min-width: 769px) {
    .protected-header-mobile {
        display: none;
    }
}
@media screen and (max-width: 768px) {
    .category-item .category-card {
        /* width: 8rem; */
        height: 4rem;
    }
    .category-card img {
        width: 2rem;
    }
    
}