.auction-heading {
    margin: 1rem 0
}
.auction-clock-icon {
    margin-right: 0.2rem;
}
.auction-item {
    margin-bottom: 2rem;
}
.auction-time-row .item {
    background-color: #F5F6F9;
    padding: 0.1rem 0.7rem;
    margin-left: 0.3rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
}
.auction-time-row .item p {
    padding: 0;
    margin: 0;
    font-size: calc(1em - 0.1vw);
}
.auction-time-row .item img {
    max-height: 90%;
}
.bid-details-row {
    background-color: #F5F6F9;
    border-radius: 10px;
    padding: 1rem;
    margin: 10px 0px;
}
.auction-desc {
    /* font-size: medium; */
}
.auction-title-mobile {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-width: 100vw;
}

.highestBidder {
    border-radius: 12px 12px 0px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    background-color: #E1FFE1;
    padding: 9px 16px;
    margin-bottom: 10px;


}

.highestBidder p {
        color: black;
        font-weight: 14px;
        font-weight: 600;
        margin: 0;
    }

.auction-title-mobile:hover {
        text-decoration: underline;
    }
@media screen and (max-width: 768px) {
    .auction-desc, .bid-details-row , .auction-details-btn {
        display: none;
    }
    .auction-time-row .item {
        /* background-color: transparent; */
        margin: 0.2rem 0;
        padding: 0;
    }
    .auction-time-row .item p {
        font-size: calc(1em - 0.2vw);
    }
    .auction-time-row .item img {
        margin-left: 0.1rem;
    }
    .auction-time-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .auction-price-mobile {
        margin: 0;
        padding: 0;
    }

}