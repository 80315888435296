.pageContainer {
  width: 100%;
  padding: 40px 0px;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .fluidContainer {
    width: 80%;
    margin: auto;
    position: relative;


    h1 {
      margin: 20px 0px;
      font-weight: bolder;
      font-size: 23px;
      font-weight: bolder;
    }

    .arrowIcon {
      position: absolute;
      position: absolute;
      left: -20px;
      width: 60px;
      height: 40px;
      top: 18px;
    }

    .fluidRow {
      display: flex;
      flex-direction: row;

      .contactCardContainer {
        background-color: #ecf1ef;
        padding: 10px 20px;
        gap: 20px;
        margin-top: 40px;

        p {
          font-size: 12px;
        }

        div {
          color: black;
          :first-child {
            color: #808185;
          }
        }
      }
    }
  }
}

.headerContainer {
  width: 80%;
  margin: auto;
}