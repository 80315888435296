.notifications-main {
    background-color: #ffff;
    border: 1px solid #C9CBD5;
    padding: 1rem;
    border-radius: 10px;
}
.notif-status, .notif-date, .notif-msg {
    padding: 0;
    margin: 0
}
.notifications-row {
    margin-bottom: 2rem;
}
.notif-msg {
    font-size: calc(1em + 0.1vw);
}

.type-label {
    background-color: lightblue;
    padding: 2px 8px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;

}

.notify-ocon {
    margin-right: 10px;
}

.main-row {
    gap: 20px;
}

@media screen and (max-width: 768px) {
    .notifications-main {
        border: none;
    }    
}