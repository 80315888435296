.pageContainer {
  width: 100%;
  padding: 40px 0px;

  .fluidContainer {
    width: 80%;
    margin: auto;
    position: relative;

    p {
      font-size: 16px;
      color: #9fa2ab;
    }

    h4 {
      font-size: 20px;
      color: #e51837;
    }

    h3 {
      font-size: 24px;
      color: black;
      font-weight: 500;
    }

    h1 {
      margin: 20px 0px;
      font-weight: bolder;
      font-size: 23px;
      font-weight: bolder;
    }

    .arrowIcon {
      position: absolute;
      position: absolute;
      left: -20px;
      width: 60px;
      height: 40px;
      top: 18px;
    }

    .topRow {
      margin: 20px 0px;

    

      .rightColContainer {
        padding-top: 25px;
        .rightMainRow {
          gap: 10px;
          .rightInfoRowInfoText {
            flex-flow: row;
            align-items: flex-start;
            border: 1px solid #e4e7ef;
            background-color: transparent;
            border-radius: 12px;
            padding: 20px;
            gap: 10px;

            p {
              margin: 0;
              color: black;
            }
          }
        }

        .rightColPayment {
          display: flex;
          flex-direction: column;

          div {
            margin: 10px 0px;
            background-color: #e4e7ef;
            border-radius: 6px;
            cursor: pointer;

            h4 {
              color: black;
            }
          }

          .rightInfoRow {
            border-radius: 12px;
            padding: 20px;
            background-color: #e4e7ef;

            p {
              font-size: 16px;
              color: #9fa2ab;
              margin: 10px 0px;
            }

            h4 {
              font-size: 20px;
              color: #e51837;
              margin: 10px 0px;

              .copyIcon {
                margin-left: 10px;
              }
            }
          }
        }
      }

      .leftColContainer {
        border-radius: 12px;
        border: 1px solid #e4e7ef;
        border-radius: 12px;
        padding: 20px;

        p {
          margin: 0;
        }

        :global(ant-progress-inner) {
          background-color: #808185;
        }

        :global(.ant-progress-bg) {
          background-color: #e51837;
        }

        input {
          margin: 20px 0px;
        }

        .totalPay {
          background-color: #e4e7ef;
          padding: 20px;
          border-radius: 12px;

          h4 {
            color: black;
          }
        }
      }

      .rightInfoRow {
        flex-flow: row;
        align-items: flex-start;
        border-radius: 12px;
        padding: 20px;
        gap: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: #e4e7ef;
        cursor: pointer;

        img {
          width: 80px;
          height: 80px;
          margin-right: 10px;
        }

        p {
          margin: 0;
          color: black;
        }

        p {
          font-size: 16px;
          color: #9fa2ab;
        }

        h4 {
          font-size: 20px;
          color: #e51837;
        }

        .uploadText {
          float: right;
          font-size: 20px;
          color: #e51837;
        }
      }

      .rightTopColContainer {
        p {
          margin: 3px 0px;
        }
      }

      .btn {
        width: 100%;
        margin: 20px 0px;
      }
    }

    .fluidRow {
      min-height: 20vh;
      display: flex;
      flex-direction: row;
      justify-content: center;

      // .leftColContainer {
      //   border-radius: 12px;
      //   border: 1px solid #e4e7ef;
      //   border-radius: 12px;
      //   padding: 20px;

      //   p {
      //     margin: 0;
      //   }

      //   :global(ant-progress-inner) {
      //     background-color: #808185;
      //   }

      //   :global(.ant-progress-bg) {
      //     background-color: #e51837;
      //   }

      //   input {
      //     margin: 20px 0px;
      //   }

      //   .totalPay {
      //     background-color: #9fa2ab;
      //     padding: 20px;
      //     border-radius: 12px;

      //     h4 {
      //       color: black;
      //     }
      //   }
      // }

      // .rightColContainer {
      //   .rightMainRow {
      //     gap: 10px;
      //     .rightInfoRowInfoText {
      //       flex-flow: row;
      //       align-items: flex-start;
      //       border: 1px solid #e4e7ef;
      //       border-radius: 12px;
      //       padding: 20px;
      //       gap: 10px;

      //       p {
      //         margin: 0;
      //         color: black;
      //       }
      //     }
      //   }

      //   .rightColPayment {
      //     display: flex;
      //     flex-direction: column;

      //     div {
      //       margin: 10px 0px;
      //       background-color: #e4e7ef;
      //       border-radius: 6px;
      //       cursor: pointer;

      //       h4 {
      //         color: black;
      //       }
      //     }

      //     .rightInfoRow {
      //       border-radius: 12px;
      //       padding: 20px;
      //       background-color: #e4e7ef;

      //       p {
      //         font-size: 16px;
      //         color: #9fa2ab;
      //         margin: 10px 0px;
      //       }

      //       h4 {
      //         font-size: 20px;
      //         color: #e51837;
      //         margin: 10px 0px;

      //         .copyIcon {
      //           margin-left: 10px;
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
}

.headerContainer {
  width: 80%;
  margin: auto;
}


.QRCodeImage {
  width: 100%;
  background-color: transparent !important;
  display: flex;
  flex-direction: row;
  justify-content: center;

  img {
    width: 20%;
  }
}