.all-auction-main {
    /* margin-top: 1rem; */
    min-height: 100vh;
}
.all-auction-main section {
    margin-bottom: 3rem !important;
}
 .auction-row {
    margin-top: 1rem;

}
.filter-section {
    /* margin-top: 1rem; */
}

.all-auction-main h2 {
    margin: 0;
    padding: 0;
}
.all-auction-main .ant-radio-wrapper span {
    font-family: Manrope-Medium;
}
@media screen and (max-width: 768px) {
    .auction-header-row {
        margin-top: 2rem;
    }
    .auction-header-row button {
        border: none;
        background-color: transparent;
    }
}