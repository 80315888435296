.pageContainer {
  width: 100%;
  padding: 40px 0px;

  .fluidContainer {
    width: 80%;
    margin: auto;
    position: relative;

    h1 {
      margin: 20px 0px;
      font-weight: bolder;
      font-size: 23px;
      font-weight: bolder;
    }

    .arrowIcon {
      position: absolute;
      position: absolute;
      left: -20px;
      width: 60px;
      height: 40px;
      top: 18px;
    }

    .fluidRow {
      min-height: 30vh;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      h6 {
        font-size: 18px;
        font-weight: bold;
        margin: 0;
        color: #808185;
      }

      p {
        margin: 0;
        font-size: 16px;
        font-family: Manrope-Regular;
      }

      h4 {
        font-size: 20px;
        font-weight: bold;
      }

      .leftRow {
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        padding: 25px;
        margin: 20px 0px;
        gap: 10px;

        .imgRow {
          flex-flow: row;
          justify-content: flex-start;
          align-items: center;
          border: 1px solid #c9cbd5;
          border-radius: 12px;
          padding: 10px;
          gap: 10px;
          width: 90%;
          

          img {
            border-radius: 10px;
            width: 100px;
            min-height: 100px;
          }

          .paraDiv {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px;
            background-color: #c9cbd55e;
            width: 100%;
            margin: 20px 0px;
          }

          
        }

        .addressRow {
            background-color: #c9cbd55e;
            padding: 20px;
            border-radius: 12px;
            border: 2px dotted #9FA2AB;
            display: flex;
            flex-direction: column;
            gap: 10px;
            cursor: pointer;
        }

        .activeAddressRow {
          background-color: #C9CBD5;
          padding: 20px;
          border-radius: 12px;
          border: 1px dotted #f28999;
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }

      .rightRow {
        border: 1px solid #C9CBD5;
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        padding: 25px;
        margin: 20px 0px;
        gap: 10px;

        button {
          margin: 20px 0px;
        }
      }
    }
  }
}

.headerContainer {
  width: 80%;
  margin: auto;
}
