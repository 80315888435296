.auction-detail-main {
    padding: 3rem;
}
.auction-detail-main h3 {
    padding: 0;
    margin: 0;
}
.auction-detail-title {
    max-width: 80%;
}
.auction-detail-main .auction-cover {
    border-radius: 0.3rem;
    /* width: 100%; */
    aspect-ratio: 1;
    height: 100%;
    object-fit: cover;
}
.auction-detail-main .auction-image-viewer {
    position: absolute;
    bottom: 0.5rem;
    left: 1.5rem;
    background-color: #808185;
    border-radius: 0.3rem;
    outline: none;
    border: none;
    padding: 0.6rem 0.8rem;
    color: #ffff;
    font-family: Manrope-Medium;
    cursor: pointer;
}
.auction-detail-main .caret {
    background-color: #808185;
    width: 0.1rem;
    height: 80%;
}
.auction-detail-main .auction-details p, h7 {
    padding: 0;
}
.auction-detail-main .auction-details {
    background-color: #ffff;
    border-radius: 0.4rem;
    padding: 0.5rem 1rem 1rem 1rem;
    border: 0.5px solid #C9CBD5;
    margin-bottom: 1rem;
}
.auction-detail-main .auction-details .bid-amount-label {
    font-size: calc(1em - 0.1vw);
}
.auction-detail-main .auction-detail-label {
    font-family: Manrope-Medium;
    color: #9FA2AB;
    font-size: calc(1em - 0.1vw);
    padding: 0;
    margin-bottom: 0.3rem;
}
.last-bid-price-div {
    background-color: #F5F6F9;
    border-radius: 0.5rem;
    width: 50%;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.watch-list-btn {
    background-color: #F5F6F9;
    border: 1px solid #C9CBD5;
    border-radius: 0.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0 1rem;
}
.watch-list-btn img {
    /* width: 1.5rem; */
}
@media screen and (max-width: 1024px) {
    .last-bid-price-div {
        width: 30%;
    }
}

@media screen and (max-width: 768px) {
    .auction-detail-main {
        padding: 3rem 0;
    }
    .auction-detail-title {
        max-width: 70%;
    }
    .auction-detail-main .auction-details {
        justify-content: space-between;
    }
    .last-bid-price-div {
        padding: 0.2rem 0;
        width: 100%;
    }
}