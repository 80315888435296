.my-bids-main {
}
.my-bids-main p, h4, h2{
    padding: 0;
    margin: 0;
}
.my-bid-details {
    background-color: #F5F6F9;
    padding: 0.5rem 0.8rem ;
    border-radius: 0.3rem;
    /* margin-top: 0.5rem; */
}
.my-bid-payment-date {
    background-color: #F5F6F9;
}
.my-bids-cell {
    background-color: #F5F6F9;
    border-radius: 0.4rem;
    padding: 0.5rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}
.my-bids-cell p {
    font-size: smaller;
}
.my-bids-cell-won {
    padding: 0.5rem;
    border-radius: 0.4rem;
    background-color: #E1FFE1;
}
.t-status-won {
    color: #28C828;
    font-size: smaller;
}
.my-bid-footer-button {
    margin-top: 1rem;
}
.my-bid-footer-button .my-bid-payment-date {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
}

.active-heading {
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.bid-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
}